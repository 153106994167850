/**
 * Loaders and loading styling
 */

.loader {
    position: absolute;
    display: none;
    z-index: 1000;
    top: 50%;
    left: 0;
    right: 0;
    width: 310px;
    text-align: center;
    padding: 18px;
    margin: auto;
    border-radius: 5px;
    border: 1px solid $primary;
    background: rgba(255, 255, 255, 0.9);
    transform: translateY(-50%);
    .loader-icon {
        display: block;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        background: url(../img/loader.png) no-repeat center center;
        background-size: contain;
        animation: spinner-animation 5s linear infinite;
        @keyframes spinner-animation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
    .loader-message {
        margin-top: 20px;
        .dot {
            font-weight: bold;
            animation-name: blink;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            &:nth-child(2) {
                animation-delay: .5s;
            }
            &:nth-child(3) {
                animation-delay: 1s;
            }
        }
        @keyframes blink {
            0% {
                opacity: 0;
            }
            20% {
                opacity: 1;
            }
            75% {
                opacity: 0;
            }
            100% {
                opacity: 0;
            }
        }
    }
}

.loading {
    pointer-events: none;
    position: relative;
    > *:not(.loader) {
        transition: all .3s ease-in-out;
        -webkit-filter: blur(5px);
        filter: blur(5px);
    }
    > .loader {
        display: block;
    }
}
