$rem-base:                          16px;

$white:                             #fff;
$black:                             #000;
$body-text-dark:                    #85979D;
$body-text-light:                   #fff;

$body-bg:                           $white;
$body-color:                        $body-text-dark;

$primary:                           #48b9be; // Green/blue
$primary-active:                    #3cadb4; // Slightly darker green/blue
$secondary:                         #34515b; // Dark-green/blue
$tertiary:                          #E96361; // Red

$success:                           #35c561;
$info:                              $primary;
$warning-severe:                    #ff7e00;
$warning:                           #ffc500;
$danger:                            #ff414d;

$link-color:                        $primary;
$link-hover-color:                  $primary-active;

$btn-border-radius:                 25px;
$btn-border-radius-sm:              25px;
$btn-border-radius-lg:              25px;
$btn-padding-x:                     30px;

$theme-colors: (
    "primary":                      $primary,
    "secondary":                    $secondary
);

$grid-breakpoints: (
    xs:                             0,
    s:                              376px, // Older phones/iPhones
    sm:                             576px,
    md:                             768px,
    lg:                             992px,
    xl:                             1200px,
    xxl:                            1440px,
    xxxl:                           2560px,
);
