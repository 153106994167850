.card-title {
    margin-bottom: 0;
    i {
        width: 40px;
        text-align: center;
    }
}

.card + .card {
    margin-top: 20px;
}
