.select2 {
    &.select2-container--bootstrap4 {
        .select2-selection--multiple {
            .select2-selection__rendered {
                display: block;
                .select2-selection__choice {
                    display: inline-block;
                    .select2-selection__choice__remove {
                        border: none;
                        padding: 0 3px;
                        background-color: transparent;
                        cursor: pointer;
                        font-size: 1em;
                        font-weight: bold;
                    }
                    .select2-selection__choice__display {
                        cursor: default;
                    }
                }
            }
        }
    }
}
