// PX to REM conversion
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base) {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem;
    @if ($value == 0rem) {
        $value: 0;
    }
    @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);
    @if $max == 1 {
        @return convert-to-rem(nth($values, 1), $base-value);
    }
    $remValues: ();
    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }
    @return $remValues;
}

// CSS arrows
@mixin arrow($size, $color, $direction) {
    pointer-events: none;
    position: absolute;
    display: block;
    content: " ";
    width: 0;
    height: 0;
    border: $size solid transparent;
    @if $direction == 'down' {
        top: 100%;
        left: 50%;
        margin-left: -$size;
        border-top: $size solid $color;
    }
    @if $direction == 'up' {
        bottom: 100%;
        left: 50%;
        margin-left: -$size;
        border-bottom: $size solid $color;
    }
    @if $direction == 'left' {
        top: 50%;
        right: 100%;
        margin-top: -$size;
        border-right: $size solid $color;
    }
    @if $direction == 'right' {
        top: 50%;
        left: 100%;
        margin-top: -$size;
        border-left: $size solid $color;
    }
}

@mixin visually-hidden {
    position: absolute !important;
    overflow: hidden !important;
    -webkit-clip-path: inset(50%) !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    width: 1px !important;
    height: 1px !important;
    white-space: nowrap !important;
    border: 0 !important;
    padding: 0 !important;
}

// Hide content
@mixin hide {
    position: absolute !important;
    overflow: hidden;
    height: 1px;
    width: 1px;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

@mixin visually-hidden {
    position: absolute !important;
    overflow: hidden !important;
    -webkit-clip-path: inset(50%) !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    width: 1px !important;
    height: 1px !important;
    white-space: nowrap !important;
    border: 0 !important;
    padding: 0 !important;
}
