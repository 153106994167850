.navbar {
    background: $white;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, .3);

    .navbar-nav {
        .nav-link {
            font-size: rem-calc(14);
            @include media-breakpoint-up(md) {
                font-size: 1rem;
            }
        }
    }
}

.navbar-brand {
    display: inline-block;
    padding: rem-calc(10);
    margin-left: 40px;
    margin-right: 0;
    @include media-breakpoint-up(md) {
        margin-left: 0;
    }

    .logo {
        height: 24px;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            height: 44px;
            margin-right: 20px;
        }
    }

    .title {
        position: relative;
        display: none;
        vertical-align: bottom;
        margin-bottom: -6px;
    }
}

.sidebar-nav {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: $primary;

    header {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 84px;
        padding: 8px 27px;

        .menu-toggle {
            display: none;
            margin-right: auto;
        }
    }

    a {
        color: #fff;

        &:hover {
            text-decoration: none;
        }
    }
}

.sidebar-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;

    > .sidebar-nav-item {
        border-radius: 25px;
    }

    .nav-item-divider {
        height: 1px;
        margin: 1rem 0;
        overflow: hidden;
        background-color: rgba(236, 238, 239, 0.3);
    }

    > li {
        overflow: hidden;
        border-radius: 25px;
        border: 1px solid transparent;
        transition: border .25 ease-in-out;

        &:not(:first-child) {
            margin-top: 10px;
        }

        &.show {
            border-color: $white;

            .sidebar-nav-dropdown-toggle:after {
                transform: rotate(0deg);
            }

            .sidebar-nav-dropdown {
                display: block;
            }
        }

        > .sidebar-nav-item.active {
            border: 1px solid $white;
            border-radius: 30px;
        }
    }
}

.sidebar-nav-item {
    min-height: 56px;
    width: 100%;
    padding: 8px 20px 8px 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.02em;
    transition: ease-out 0.5s;
    border: 1px solid transparent;

    &:not(:first-child) {
        margin-top: 10px;
    }

    &:hover {
        background: $primary-active;
    }

    &.active {
        background: $primary-active;
    }

    i {
        width: 36px;
        font-size: 19px;
        margin-left: -40px;
    }
}

.sidebar-nav-dropdown-toggle:after {
    content: "";
    margin-left: auto;
    margin-right: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $white;
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: transform .25s ease-in-out;
}

.sidebar-nav-dropdown {
    display: none;
    padding: 0;
    margin: 0;
}
