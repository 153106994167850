table {
    th {
        a.sort {
            padding-left: 1rem;
            color: inherit;
            display: block;
            position: relative;

            &:before, &:after {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                font-size: 1rem;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                opacity: .3;
            }

            &:before {
                content: "\f0de";
            }

            &:after {
                content: "\f0dd";
            }

            &.asc:before, &.desc:after {
                opacity: 1;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.table--overview {
        tr:hover {
            background-color: rgba(72, 185, 190, 0.1);
        }
    }
    td.toggle {
        cursor: pointer;
        .btn {
            padding: 4px 10px;
        }
    }

    .no-border {
        border: none !important;
    }
}
