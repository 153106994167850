.vue-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.vue-modal {
    background: #fff;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.vue-modal-header,
.vue-modal-footer {
    padding: 15px;
    display: flex;
}

.vue-modal-header {
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.vue-modal-footer {
    border-top: 1px solid #eee;
    flex-direction: column;
}

.vue-modal-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: 55px;
    color: $black;
    font-size: 24px;
    border: none;
    background: none;
}

.vue-modal-fade-enter,
.vue-modal-fade-leave-to {
    opacity: 0;
}

.vue-modal-fade-enter-active,
.vue-modal-fade-leave-active {
    transition: opacity .5s ease;
}
