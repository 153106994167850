
















































.password-strength-label {
    font-size: .7em;
    text-transform: uppercase;
    margin-bottom: -6px;
}

.password-strength-bar {
    height: 5px;
    margin-top: 8px;
    border-radius: 2px;
    transition: all 0.2s linear;

    &.risky {
        width: 10%;
        background-color: #f95e68;
    }

    &.guessable {
        width: 32.5%;
        background-color: #fb964d;
    }

    &.weak {
        width: 55%;
        background-color: #fdd244;
    }

    &.safe {
        width: 77.5%;
        background-color: #b0dc53;
    }

    &.secure {
        width: 100%;
        background-color: #35cc62;
    }
}
