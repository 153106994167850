body {
    &.menu-active {
        overflow: hidden;
        @include media-breakpoint-up(md) {
            overflow: initial;
        }
    }
}

// Use v-cloak attribute for Vue components
[v-cloak] {
    display: none !important;
}

.hide {
    @include hide;
}

.visually-hidden {
    @include visually-hidden;
}
