.menu-toggle {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    user-select: none;
    width: 40px;
    position: absolute;
    top: 6px;
    left: 6px;

    .line {
        fill: none;
        transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
        stroke: #000;
        stroke-width: 5.5;
        stroke-linecap: round;
    }

    .top {
        stroke-dasharray: 40 121;
    }

    .bottom {
        stroke-dasharray: 40 121;
    }

    &.active {
        transform: rotate(45deg);

        .top {
            stroke-dashoffset: -68px;
        }

        .bottom {
            stroke-dashoffset: -68px;
        }
    }
}

.content-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100; /* Behind the navbar */
    padding: 50px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    transform: translateX(-100%);
    transition: all .25s ease-in-out;

    &.active {
        transform: translateX(0);
    }

    @include media-breakpoint-up(md) {
        padding-top: 64px;
        transform: translateX(0);
        transition: none;
    }
}
