.sortable-chosen {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.03);

    &.sortable-ghost {
        opacity: 0.3;
    }
}

.sortable-drag {
    opacity: 1;
    color: $white !important;
    background-color: $primary;

    a {
        color: $white !important;
    }
}

.sortable-error {
    display: none;
    color: $danger;
    margin-bottom: 10px;

    &.active {
        display: block;
    }
}

.sortable-ghost {
    opacity: 0.1;
}
