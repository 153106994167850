





















































































@import "../../style/general/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.dual-list {
  .list-box-wrapper {
    .btn-action {
      cursor: pointer;
      border-color: $primary;
      background-color: $primary;
    }

    .actions {
      padding: 0 10px;
    }

    .list-box-item {
      border-radius: 4px;

      .bulk-action {
        display: block;
        @include media-breakpoint-up(lg) {
          display: flex;
        }

        .select-all {
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          padding: 10px;
          background-color: $primary;
        }

        .deselect-all {
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          padding: 10px;
          background-color: $secondary;
        }
      }
    }
  }
}
