// Variables
@import "general/variables";
@import "general/mixins";

// Plugins
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~dual-listbox-vue/dist/dual-listbox.css";
@import '~flag-icon-css/sass/flag-icon';
@import '~flatpickr/dist/flatpickr.min.css';
@import "~select2/dist/css/select2.min.css";
@import "~summernote/dist/summernote-bs4.min.css";
@import '~vue-datetime/dist/vue-datetime.css';
@import "~vue-select/src/scss/vue-select.scss";
@import '~vue2-dropzone/dist/vue2Dropzone.min.css';

// General
@import "general/layout";

// Components
@import "components/lists";
@import "components/assets";
@import "components/buttons";
@import "components/cards";
@import "components/dropzone";
@import "components/flatpicker";
@import "components/forms";
@import "components/loader";
@import "components/navbar";
@import "components/select2";
@import "components/sidebar";
@import "components/sortable";
@import "components/tables";
@import "components/vue/modal";
@import "components/vue/select";

// Swagger UI
@import "general/swagger_ui";
