.flatpickr-calendar.arrowTop:after {
    border-bottom-color: $primary;
}
.flatpickr-calendar.arrowBottom:after {
    border-top-color: $primary;
}
.flatpickr-months .flatpickr-month {
    color: $white;
    background: $primary;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: $primary;
}
.flatpickr-weekdays {
    color: $white;
    background: $primary;
}
span.flatpickr-weekday {
    color: $white;
    background: $primary;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $primary;
    border-color: $primary;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 $primary;
    box-shadow: -10px 0 0 $primary;
}
.flatpickr-day.week.selected {
    -webkit-box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
    box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    color: $white;
    fill: $white;

    &:hover {
        color: #959ea9;
        fill: #959ea9;
    }
}
